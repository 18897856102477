// This file is necessary in to properly order to import jQuery on the global namespace with Parcel
// See https://github.com/parcel-bundler/parcel/issues/333
import jquery from "../../node_modules/jquery/dist/jquery";

export default (window.$ = window.jQuery = jquery);

// Test jQuery 
//window.$ = window.jQuery = require('../node_modules/jquery/dist/jquery.js');
// jQuery(function() {
//   console.log("ready");
// });