// Import CSS
import "./scss/style.scss"
import "./css/progress-bar.css"
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css"

//Import JavaScript
import "../node_modules/bootstrap/dist/js/bootstrap"
import "./js/import-jquery";
import "./js/player";
import "./js/form";

$(document).ready(function () {
  $('.media-player').mediaPlayer('init', { 
    size: 150,
    // color: '#101535',
    // fill: '#8ebbc4'
    fill: '#FFFFFF'
  });
});

import "./js/parallax";
import "./js/scroll";
