//Smooth scroll
$('a[href^="#"]').on('click', function(event) {
  var href = this.getAttribute('href');
  // console.log("href: %s", href);
  if (href.length < 2) {
    return;
  }
  var target = $(href);
  if( target.length ) {
      event.preventDefault();
      $('html, body').stop().animate({
          scrollTop: target.offset().top
      }, 1000);
  }
});

$('#listen-link').click(function(e) {
  $('#playable0 .controls').click();
});

