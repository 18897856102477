$(document).ready(function(){

  // Populate images from data attributes.
  var scrolled = $(window).scrollTop();

  // Attach scroll event to window. Calculate the scroll ratio of each element
  // and change the image position with that ratio.
  // https://codepen.io/lemagus/pen/RWxEYz
  $(window).on('scroll', function() {
    var scrolled = $(window).scrollTop();
    $('.parallax').each(function(index, element) {
      var initY = $(this).offset().top;
      var height = $(this).height();
      // var endY  = initY + height;

      // var $showcase = $('.voice-description');
      // initY += $showcase.height() / 2;
      // console.log("initY: %s, height: %s", initY, height);

      // First check and see if we are even supporting parallax
      var mode = $(this).css('background-attachment');
      if (mode == 'scroll') {
        $(this).css('background-position-y', 0);
        return;
      }

      // Next check if the element is in the viewport.
      var visible = isInViewport(this);
      if (visible) {
        var diff = scrolled - initY;
        var ratio = Math.round((diff / height) * 100);
        $(this).css('background-position-y', parseInt(-(ratio * 1.5)) + 'px');
      }
    })
  })
  .on('resize', function() {
    $(this).css('background-position-y', 0);
  })
})

// Check if the element is in the viewport.
// http://www.hnldesign.nl/work/code/check-if-element-is-visible/
function isInViewport(node) {
  // Am I visible? Height and Width are not explicitly necessary in visibility
  // detection, the bottom, right, top and left are the essential checks. If an
  // image is 0x0, it is technically not visible, so it should not be marked as
  // such. That is why either width or height have to be > 0.
  var rect = node.getBoundingClientRect();
  return (
    (rect.height > 0 || rect.width > 0) &&
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
}