$(document).ready(function() {

  var processFormResults = function($form, data) {
    // console.log(data);

    var $alert = $form.parent().find('.alert');
    if (!$alert.length) {
      $alert = $('<div/>').addClass('alert').addClass('alert-dismissable');
      $alert.append('<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>');
      $alert.append('<h4 class="alert-heading"></h4>');
      $alert.append('<div class="alert-content">');
      $form.before($alert);
    } 
    else {
      $alert.removeClass('alert-danger').find('.alert-content').text(''); //reset
    }
    
    if (data.success) {
      $form.slideUp();
      $alert.addClass('alert-success').find('.alert-heading').text('Success!');
      $alert.find('.alert-content').append(data.message);
    }
    else {
      $alert.addClass('alert-danger');
      if (data.errors) {
        var msg = '<p>Please correct the following and try again:</p><ul>';
        $.each(data.errors, function(i, el) {
          msg += `<li>${el}</li>`; 
        })
        msg += '</ul>';
        $alert.find('.alert-content').append(msg);
      }
      else {
        $alert.find('.alert-heading').text('Uh oh');
        $alert.find('.alert-content').append("An unknown error has occurred. Please try again. If things still aren't cooperating please just pick up the phone and give us a call!");
      }

    }

    $alert.on('closed.bs.alert', function () {
      if (data.success) {
        $(':input', $form).val('');
      }
      $form.slideDown();
    })
  }

  // process the form
  $('form').submit(function(e) {
      var $form = $(this);
      
      var formData = {};
      $.map(
        $form.serializeArray(), 
        function (n, i) { 
          formData[n['name']] = n['value']; 
        }
      );

      // console.log(formData);

      // process the form
      $.ajax({
          type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
          url         : $form.attr('action'), // the url where we want to POST
          data        : formData, // our data object
          dataType    : 'json', // what type of data do we expect back from the server
          encode      : true
      })
        // using the done promise callback
        .done(function(data) {
          processFormResults($form, data);
        })
        .fail(function(data) {
          processFormResults($form, data);
        });

      // stop the form from submitting the normal way and refreshing the page
      e.preventDefault();
  });

});